import $ from 'jquery';



// Set default consent to 'denied' as a placeholder
// Determine actual values based on your own requirements
gtag('consent', 'default', {
    'ad_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'analytics_storage': 'denied'
});


let cookie_services = {
    google_analytics_enabled: true,
    google_ads_enabled: true,
    facebook_enabled: true,
    microsoft_adv_enabled: true,
    adcell_enabled: true
};

let myGlobal = {
    acceptAll: function () {

        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted'
        });

        myGlobal.enableGoogleTracking();
        myGlobal.submitAcceptedCookies();
    },
    enableGoogleTracking: function () {

        if(cookie_services.google_ads_enabled && cookie_services.google_analytics_enabled) {
            gtag('js', new Date());
            gtag('config', 'G-BFG3V8L0Z6');
    
            gtag('set', 'conversion_linker', true);
            gtag('set', 'allow_ad_personalization_signals', true);
            gtag('event', 'pageview');
        }

    },
    enableFacebookTracking: function() {
        if(cookie_services.facebook_enabled) {
            console.log("fb ok");
            ! function(f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function() {
                    n.callMethod ?
                        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s)
            }(window, document, 'script',
                'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1580804025326799');
            fbq('track', 'PageView');
        }
    },
    enableMicrosoftAdsTracking: function() {
        if(cookie_services.microsoft_adv_enabled) {
            console.log("loading microsoft adv");
    
            (function(w,d,t,r,u) {
                var f,n,i;
                w[u]=w[u]||[],f=function(){var o={ti:"27027004"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},  
                n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function() {
                var s=this.readyState;
                s && s!=="loaded" && s!=="complete"||(f(),n.onload=n.onreadystatechange=null);
                },  
                i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i);
            })(window,document,"script","https://bat.bing.com/bat.js","uetq");
        }
    },
    submitAcceptedCookies: function () {
        $("#cookie-consent-popup-detail-container").hide();
        $("#cookie-consent-popup").hide();

        myGlobal.enableGoogleTracking();

        myGlobal.enableFacebookTracking();
        myGlobal.enableMicrosoftAdsTracking();

        // enable other scripts
        /*
        document.querySelectorAll("script[data-cookie-block]").forEach(script => {

            // enable only scripts that are accepted from the user
            if (script.getAttribute('data-unlock') === "true") {
                const newScript = document.createElement("script");
                newScript.textContent = script.textContent;
                newScript.async = script.hasAttribute("async");
                document.head.appendChild(newScript);
            }

        });
        */
    }
};


$(document).ready(function () {

    const cookiePopup = $(".cookie-consent-popup");

    $(".collapse-button").click(function () {
        let id_to_collapse = $(this).data('collapse-id');
        $("#" + id_to_collapse).toggle();
    })


    if (localStorage.getItem("cookieConsent") === "accepted") {
        myGlobal.submitAcceptedCookies();
    } else if (localStorage.getItem("cookieConsent") === "declined") {

    } else {
        cookiePopup.removeClass('hidden');
    }

    // initialize toggle buttons
    if (localStorage.getItem("cookieServices") === null) {
        localStorage.setItem("cookieServices", JSON.stringify(cookie_services));
    } else {
        cookie_services = JSON.parse(localStorage.getItem("cookieServices"));
        $(".cookie-toggle-google").attr('checked', cookie_services.google_analytics_enabled);
        $(".cookie-toggle-google-ads").attr('checked', cookie_services.google_ads_enabled);
        $(".cookie-toggle-facebook").attr('checked', cookie_services.facebook_enabled);
        $(".cookie-toggle-microsoft-adv").attr('checked', cookie_services.microsoft_adv_enabled);
    }

    $(".show-cookie-consent-detail-popup").on('click', function () {
        $("#cookie-consent-popup-detail-container").show();
    })

    $(".accept-all-cookies").on("click", function () {
        localStorage.setItem("cookieConsent", "accepted");

        myGlobal.acceptAll();
    });

    $(".decline-all-cookies").on("click", function () {
        localStorage.setItem("cookieConsent", "declined");
        cookiePopup.addClass('hidden');
    });

    $(".confirm-cookies").on('click', function () {
        localStorage.setItem("cookieConsent", "accepted");

        myGlobal.submitAcceptedCookies();
    })

    $(".tab-button").click(function () {
        $(".cookie-consent-popup-tab").addClass('hidden');
        $(".tab-button").removeClass('selected');
        $(this).addClass('selected');
        $("." + $(this).data('show-tab')).removeClass('hidden');
    })

    $(".cookie-toggle").change(function () {
        let status = $(this).is(':checked');
        let status_string = 'denied';
        let cookie_type = $(this).data('cookie-type');

        if (status == true)
            status_string = 'granted';

        if (cookie_type == 'analytics') {
            gtag('consent', 'update', {
                'analytics_storage': status,
            });
            cookie_services.google_analytics_enabled = status;
        } else if (cookie_type == 'marketing') {
            gtag('consent', 'update', {
                'ad_storage': status,
                'ad_user_data': status,
                'ad_personalization': status,
            });
            cookie_services.google_ads_enabled = status;
        }
        else if (cookie_type == 'facebook') {
            cookie_services.facebook_enabled = status;
            $(".facebook-pixel-script").attr('data-unlock', status);
            //myGlobal.unlockScripts();
        }
        else if (cookie_type == 'microsoft') {
            cookie_services.microsoft_enabled = status;
            $(".microsoft-adv-script").attr('data-unlock', status);
        }
        else if (cookie_type == 'adcell') {
            cookie_services.adcell_enabled = status;
            // $("#facebook-pixel-script").data('unlock', status);

        }

        localStorage.setItem("cookieServices", JSON.stringify(cookie_services));

    })
});